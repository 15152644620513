import React from 'react';
import { withPrefix } from 'gatsby-link';
import { Row, Col } from 'react-flexbox-grid';
import ActiveLink from '../ActiveLink';
import './db.scss';

const DownloadBlock = props => {
  const category = props.VideoLink ? 'Video Watch Button' : 'PDF Download';

  return (
    <div className="db-container">
      <Row>
        <Col xs={4}>
          <img
            src={require(`../../../assets/images/${props.ThumbnailImage}`)}
            className="pdf-thumbnail"
          />
        </Col>
        <Col xs={8}>
          <span className="copy-matter">{props.children}</span>
          <ActiveLink
            DownloadLink
            DeadLink={props.DeadLink === 'true'}
            VideoLink={props.VideoLink === true}
            Direct={props.Direct === true}
            VideoId={props.VideoId ? props.VideoId : ''}
            Link={props.Link ? props.Link : ''}
            EnableActiveClass={false}
            to={withPrefix(`/pdfs/${props.DownloadFile}`)}
            EventObject={{
              category: category,
              action: 'Click',
              label: props.LinkCaption,
            }}
            className={props.className}
          >
            {props.LinkCaption}
          </ActiveLink>
        </Col>
      </Row>
    </div>
  );
};

export default DownloadBlock;
