import React from 'react';
// import windowSize from 'react-window-size';
import ScrollableAnchor, {
  goToAnchor,
  configureAnchors,
} from 'react-scrollable-anchor';
import { Grid, Row, Col } from 'react-flexbox-grid';
import SourceEmitter from 'libs/emitter';

// <--CUSTOM IMPORTS START
import ContentBlock from 'components/Content/ContentBlock';
import ColorBlock from 'components/Content/ColorBlock';
import DownloadBlock from 'components/LandingPage/download-block';
import VideoContainer from 'components/VideoContainer';
import BrandCTA from 'components/BrandCta';
import { Layout, Seo } from 'components';
import { getIndication, siteIndications } from 'libs/utilityHelper';

// placeholder img
import videoThumb from '../assets/images/nutrition-hcp-logo.png';

// SASS
import './todaysdietitian2021.scss';

// INITIALIZATIONS
let Subscription = null;
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      directLinkVideo: false,
    };
  }

  // This function required in every page to magically calculate appropriate offset
  calculatePageOffset(currentWindowWidth) {
    let pageOffset = -60; // Default offset when hamburger is not displayed

    if (currentWindowWidth <= 1031 && currentWindowWidth > 776) {
      // Equivalent to <=1023 and >768
      pageOffset = -252;
    } else if (currentWindowWidth <= 776) {
      // Equivalent to <-768
      pageOffset = -252;
    } else {
      // All other breakpoints
      pageOffset = -220;
    }

    return pageOffset;
  }

  triggerHashChange = () => {
    const currentHash = window?.location.hash.substring(
      1,
      window?.location.hash.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  componentDidMount() {
    window.addEventListener('hashchange', this.triggerHashChange, false);

    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });

    this.setResponseParams();
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.triggerHashChange, false);
    Subscription && Subscription.remove();
  }

  setResponseParams() {
    const responseObj = window.location.hash
      .substr(1)
      .split('&')
      .map(el => el.split('='))
      .reduce((pre, cur) => {
        pre[cur[0]] = cur[1];
        return pre;
      }, {});
    this.setState({
      directLinkVideo: responseObj.video,
    });
  }

  pageHeaderContent() {
    return (
      <div className="body-title center-align">
        Welcome! We appreciate your continued interest in NASCOBAL
        <sup>&reg;</sup> Nasal Spray&mdash;&mdash;the only FDA-approved
        prescription vitamin B<sub>12</sub> nasal spray.<sup>1</sup> Below you
        will find resources to support your treatment of appropriate patients
        with vitamin B<sub>12</sub> deficiency.
      </div>
    );
  }

  render() {
    // const brandNames = {
    //   nascobal: 'NASCOBAL<sup className="brand-reg-mark">&reg;</sup>',
    //   nutrition:
    //     '<nobr>Nutrition Direct<span className="brand-trade-mark">&trade;</span></nobr>',
    //   bariactiv: 'BariActiv<sup className="brand-reg-mark">&reg;</sup>',
    //   NASCOBAL: "NASCOBAL®",
    //   NUTRITION:
    //     '<nobr>Nutrition Direct<span className="brand-trade-mark">&trade;</span></nobr>',
    //   BARIACTIV: 'BariActiv<sup className="brand-reg-mark">&reg;</sup>',
    // };

    // const { currentIndication } = useContext(appContext);

    // Offset magic happens here
    configureAnchors({
      offset: this.calculatePageOffset(this.props.windowWidth),
    });

    const indication =
      (typeof window !== 'undefined' && getIndication()) ||
      siteIndications.patient;

    return (
      <Layout indication={indication} className="patient-home">
        <Seo
          pageTitle="NUTRITION DIRECT™ | HCP"
          pageKeywords="Nascobal, Vitamin B12, Nasal Spray"
          pageDescription="Learn about Nascobal nasal spray"
        />
        <div id="landing-page">
          <ScrollableAnchor id="bodyTop">
            <div>
              {this.pageHeaderContent()}
              <ContentBlock
                id="enrollment-forms-and-faqs-block"
                className="no-padding-top margin-offset"
              >
                <Grid
                  fluid
                  style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 45 }}
                >
                  <ColorBlock white id="colorblock-enrollment-forms-and-faqs">
                    <Row>
                      <Col xs={12} md={6}>
                        <DownloadBlock
                          DownloadFile="NS-05571_NASCOBAL_Patient_Profile_Endocrinologist_Digital_LP.pdf"
                          LinkCaption="DOWNLOAD NOW"
                          ThumbnailImage="landing_page_patient_profile_endocrinologists_thumb.png"
                          className="enrollment-form-btn"
                        >
                          <div className="tile-title">
                            Patient Profile for <br /> Endocrinologists
                          </div>
                          <p>
                            Download to help quickly identify patients with
                            vitamin B<sub>12</sub> deficiency
                          </p>
                        </DownloadBlock>
                      </Col>
                      <Col xs={12} md={6}>
                        <DownloadBlock
                          DownloadFile="Crohns-Patient-Brochure.pdf"
                          LinkCaption="WATCH NOW"
                          ThumbnailImage="landing_page_ND_Video_2.thumb.png"
                          VideoId="nd-pre-operative-video"
                          VideoLink
                        >
                          <div className="tile-title">
                            Video Featuring Julie <br /> Johannes, Physician
                            Assistant
                          </div>
                          <p>
                            Share with patients to help them better understand
                            the nutritional needs of <br />
                            post-weight loss surgery patients
                          </p>
                        </DownloadBlock>
                      </Col>
                    </Row>
                    <Row className="second-row">
                      <Col xs={12} md={6}>
                        <DownloadBlock
                          DownloadFile="NS-05520_NASCOBAL_Patient_Education_Brochure_Digital_LP.pdf"
                          LinkCaption="DOWNLOAD NOW"
                          ThumbnailImage="landing_page_NASCOBAL_Patient_Education_Brochure_thumb.png"
                        >
                          <div className="tile-title">
                            Patient Education Brochure
                          </div>
                          <p className="second">
                            Download and share with patients to <br /> explain
                            vitamin B<sub>12</sub> deficiency and <br />
                            NASCOBAL<sup>&reg;</sup> Nasal Spray
                          </p>
                        </DownloadBlock>
                      </Col>
                      <Col xs={12} md={6}>
                        <DownloadBlock
                          DownloadFile="NS-05447_NASCOBAL_Nutrition_Direct_Patient_Brochure_LP.pdf"
                          LinkCaption="DOWNLOAD NOW"
                          ThumbnailImage="landing_page_Nutrition_Direct_Patient_Brochure_thumb.png"
                        >
                          <div className="tile-title">
                            Nutrition Direct<sup>&trade;</sup> Brochure
                          </div>
                          <p className="second">
                            Download and share with patients to <br /> explain
                            how they can get their monthly <br /> supply of
                            nutritional supplements <br />
                            delivered at home
                          </p>
                        </DownloadBlock>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} className="btn-container">
                        <p className="blue-color">
                          Learn more about the only FDA-approved prescription
                          vitamin B<sub>12</sub> nasal spray<sup>1</sup>
                        </p>
                        <BrandCTA
                          href="/vitaminb12/hcp"
                          LinkCaption="VISIT NASCOBAL.COM"
                          ExternalLink={false}
                        >
                          VISIT NASCOBAL.COM
                        </BrandCTA>
                      </Col>
                      <Col xs={12} md={6} className="btn-container">
                        <p className="blue-color">
                          Our monthly nutritional supplement program may be
                          right for your bariatric patients
                        </p>
                        <BrandCTA
                          href="/nutritiondirect/hcp"
                          LinkCaption="VISIT NUTRITIONDIRECT.COM"
                          ExternalLink={false}
                        >
                          VISIT NUTRITIONDIRECT.COM
                        </BrandCTA>
                      </Col>
                    </Row>
                  </ColorBlock>
                </Grid>
              </ContentBlock>
              <VideoContainer
                poster={videoThumb}
                url="https://fast.wistia.com/embed/medias/jzbz9tj4os"
                smallThumb
                carousel
                directLink={this.state.directLinkVideo === 'ndvideo1'}
                videoId="nd-pre-operative-video"
                dlURL=""
              />
            </div>
          </ScrollableAnchor>
          <ScrollableAnchor id="isi_anchor">
            <div />
          </ScrollableAnchor>
        </div>
      </Layout>
    );
  }
}
export default IndexPage;
